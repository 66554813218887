/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { MenuItem as ComplexMenuItemType } from 'bundles/megamenu/types/MenuItem';

const styles = {
  name: () => css`
    font-size: 14px;
    line-height: 20px;
  `,
  value: () => css`
    font-size: 12px;
    line-height: 16px;
    color: #5e5e5e;
  `,
  text: () => css`
    color: #1f1f1f;
  `,
  dot: () => css`
    padding: 0 4px;
    color: #5e5e5e;
  `,
  withDot: () => css`
    position: static;
    display: inline-block;
  `,
};

const DescriptionWithValues = ({ item }: ComplexMenuItemType) => {
  const { name, value } = item;

  return (
    <div className="rc-DescriptionWithValues">
      <div className="descriptionWithValues-name" css={[styles.name, styles.text]}>
        {name}
      </div>
      <div className="descriptionWithValues-valuesContainer">
        {value?.map((valueItem, index) => (
          <span key={`${name}~${valueItem}`}>
            {index > 0 && (
              <span className="descriptionWithValues-dot" css={styles.dot} key={`valueDot~${valueItem}`}>
                •
              </span>
            )}
            <span className="descriptionWithValues-value" css={[styles.value, index > 0 && styles.withDot]}>
              {valueItem}
            </span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default DescriptionWithValues;
