/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useTracker } from '@coursera/event-pulse/react';

import { DEGREES_SECTION_TYPE } from 'bundles/megamenu/constants/constants';
import type { MenuItem as SimpleMenuItemType } from 'bundles/megamenu/types/MenuItem';
import { shouldShowNewMegamenu } from 'bundles/megamenu/utils/experimentUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

const styles = {
  SimpleGoalItem: () => css`
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;
    width: 100%;
    display: inline-block;
    text-decoration: none;

    :hover {
      text-decoration: none;
    }

    :focus {
      text-decoration: none;
    }
  `,
  mobileSimpleGoalItem: () => css`
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;
    width: 100%;
    display: inline-block;
  `,
  collapseToSingleColumn: () => css`
    @media screen and (max-width: 1300px) {
      width: 100%;
    }
  `,
  hideAnchorStyling: () => css`
    color: #1f1f1f;
    padding: 8px 0;
  `,
};

const getLinkTrackingName = (isMobile?: boolean, trackingName?: string) => {
  if (trackingName) {
    return trackingName;
  } else if (isMobile) {
    return 'mobile_megamenu_item';
  } else {
    return 'megamenu_item';
  }
};

const getItemType = (type?: string) => {
  if (type === DEGREES_SECTION_TYPE) {
    return 'ddp';
  } else {
    return `other`;
  }
};

const MegaMenuGoalItem = ({
  item,
  hideAnchorColor,
  hitPosition,
  isMobile,
  tabName,
  tabId,
  sectionName,
  isSimplifiedMegaMenu,
}: SimpleMenuItemType) => {
  const track = useTracker();

  const { name, url, trackingName, type } = item;

  const getEntityType = (pathname: string) => {
    if (pathname.includes('degrees')) {
      return 'degrees_page';
    } else if (pathname.includes('certificates')) {
      return 'certificates_page';
    } else if (pathname.includes('collections')) {
      return 'collections_page';
    } else {
      return `search_page`;
    }
  };

  return (
    <TrackedDiv
      trackingName={getLinkTrackingName(isMobile, trackingName)}
      withVisibilityTracking={false}
      trackClicks={false}
      trackMouseEnters={true}
      data={{
        name,
        entityType: getEntityType(url),
        entityId: url,
        hitPosition,
        url,
        type: 'simple_menu_item',
        hideAnchorColor,
        tabName,
        sectionName,
      }}
    >
      <TrackedA
        trackingName={getLinkTrackingName(isMobile, trackingName)}
        className="rc-SimpleGoalItem"
        css={[
          !isSimplifiedMegaMenu && isMobile && styles.mobileSimpleGoalItem,
          !isSimplifiedMegaMenu && !isMobile && styles.SimpleGoalItem,
          hideAnchorColor && styles.hideAnchorStyling,
          styles.collapseToSingleColumn,
        ]}
        href={url}
        data={{ name, url, type: 'simple_menu_item', hideAnchorColor, tabName, sectionName }}
        tabIndex={isMobile || isSimplifiedMegaMenu ? 0 : -1}
        onClick={() => {
          track('click_megamenu_item', {
            megamenuItemLink: url,
            megamenuItemName: name.toLowerCase().replace(/\s+/g, '-'),
            megamenuSection: tabId ?? '',
            megamenuItemType: shouldShowNewMegamenu() ? getItemType(type) : 'other',
          });
        }}
      >
        {name}
      </TrackedA>
    </TrackedDiv>
  );
};

export default MegaMenuGoalItem;
