/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import Imgix from 'js/components/Imgix';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { useTracker } from '@coursera/event-pulse/react';

import DescriptionWithPartner from 'bundles/megamenu/components/goal-item/description/DescriptionWithPartner';
import DescriptionWithValue from 'bundles/megamenu/components/goal-item/description/DescriptionWithValue';
import type { MenuItem as ComplexMenuItemType } from 'bundles/megamenu/types/MenuItem';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/megamenu';

const styles = {
  rowFlex: () => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 20px;
    padding-top: 2px;
    padding-left: 2px;

    :hover {
      text-decoration: none;
    }

    :focus {
      text-decoration: none;
    }
  `,
  descriptionMargin: () => css`
    margin-left: 8px;
  `,
  partnerImage: () => css`
    height: 36px;
    width: 36px;
    border-radius: 4px;
    border: 1px solid #e3e3e3;
  `,
};

const getLinkTrackingName = (isMobile?: boolean, trackingName?: string) => {
  if (trackingName) {
    return trackingName;
  } else if (isMobile) {
    return 'mobile_megamenu_item';
  } else {
    return 'megamenu_item';
  }
};

const MegaMenuGoalItem = ({ item, hitPosition, tabName, tabId, sectionName, isMobile }: ComplexMenuItemType) => {
  const track = useTracker();

  const { url, partnerName, partnerLogo = '', value = [], name, trackingName, id, type } = item;

  const hasValues = !!value?.length;
  const descriptiveContent = hasValues ? <DescriptionWithValue item={item} /> : <DescriptionWithPartner item={item} />;
  const getEntityId = (entityId: string) => {
    if (entityId.startsWith(`degree`)) {
      return entityId;
    } else {
      return tupleToStringKey(['SPECIALIZATION', entityId]);
    }
  };
  const entityType = type?.includes(`DEGREE`) ? `DEGREE` : `PROFESSIONAL_CERTIFICATE`;
  const ImageEl = (
    <Imgix
      className="megaMenuGoalItem-partnerImage"
      css={styles.partnerImage}
      width={36}
      height={36}
      alt={_t('#{partnerName} logo', { partnerName })}
      src={partnerLogo}
      aria-hidden={true}
    />
  );

  return (
    <TrackedDiv
      trackingName={getLinkTrackingName(isMobile, trackingName)}
      withVisibilityTracking={false}
      trackClicks={false}
      trackMouseEnters={true}
      data={{
        name,
        entityType,
        entityId: getEntityId(id),
        hitPosition,
        url,
        partnerName,
        value,
        tabName,
        sectionName,
      }}
    >
      <TrackedA
        trackingName={getLinkTrackingName(isMobile, trackingName)}
        href={url}
        className="rc-MegaMenuGoalItem"
        css={styles.rowFlex}
        data={{ name, url, type: 'complex_menu_item', partnerName, value, tabName, sectionName }}
        tabIndex={isMobile ? 0 : -1}
        onClick={() => {
          track('click_megamenu_item', {
            megamenuItemLink: url,
            megamenuItemName: name.toLowerCase().replace(/\s+/g, '-'),
            megamenuSection: tabId ?? '',
            megamenuItemType: entityType === 'DEGREE' ? 'ddp' : 'xdp',
          });
        }}
      >
        {ImageEl}
        <div className="megaMenuGoalItem-info" css={styles.descriptionMargin}>
          {descriptiveContent}
        </div>
      </TrackedA>
    </TrackedDiv>
  );
};
export default MegaMenuGoalItem;
