import contentfulGql from 'js/lib/contentfulGql';

import { campaignCollectionsModuleFragment } from 'bundles/campaigns/contentful-queries/CollectionsModuleFragment';
import { campaignHeroModuleFragment } from 'bundles/campaigns/contentful-queries/HeroModuleFragment';
import { campaignPromoBarModuleFragment } from 'bundles/campaigns/contentful-queries/PromoBarModuleFragment';

export const campaignHeroFragment = contentfulGql`#graphql
  ${campaignHeroModuleFragment}
  fragment campaignHero on CampaignHeroModule {
    entryTitle
    creativesForVariantBCollection(limit: 10) {
      items {
        ...CampaignHeroModule
      }
    }
  }
`;

export const campaignCollectionsFragment = contentfulGql`#graphql
  ${campaignCollectionsModuleFragment}
  fragment campaignCollections on CampaignSpotlightModule {
    entryTitle
    creativesForVariantBCollection(limit: 5) {
      items {
        ...CampaignCollectionsModule
      }
    }
  }
`;

export const campaignPromoBarFragment = contentfulGql`#graphql
  ${campaignPromoBarModuleFragment}
  fragment campaignPromoBar on CampaignPromoBar {
    entryTitle
    creativesForVariantBCollection(limit: 5) {
      items {
        ...CampaignPromoBarModule
      }
    }
  }
`;

export const campaignQuery = contentfulGql`#graphql
  ${campaignHeroFragment}
  ${campaignCollectionsFragment}
  ${campaignPromoBarFragment}
  query CampaignQuery($id: String!, $preview: Boolean!) {
    campaign(id: $id, preview: $preview) {
      regionsCollection(limit: 1 ) {
        items {
          surfaceAreasCollection(limit: 10) {
            items {
              ...campaignHero
              ...campaignCollections
              ...campaignPromoBar
            }
          }
        }
      }
    }
  }
`;
