import * as React from 'react';
import { createContext } from 'react';

import type {
  CampaignCollectionsModuleFragment,
  CampaignHeroModuleFragment,
  CampaignPromoBarModuleFragment,
} from 'bundles/campaigns/__generated__/contentfulTypes';

type CampaignModulesDataContextType = {
  loading?: boolean;
  campaignData?: {
    heroModule?: {
      contentData?: CampaignHeroModuleFragment;
    };
    collectionsModule?: {
      contentData?: CampaignCollectionsModuleFragment;
    };
    promoBarModule?: {
      contentData?: CampaignPromoBarModuleFragment;
    };
  };
};

const CampaignModulesDataContext = createContext<CampaignModulesDataContextType>({});

export const useCampaignModulesData = () => {
  return React.useContext(CampaignModulesDataContext);
};

export default CampaignModulesDataContext;
