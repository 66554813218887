import contentfulGql from 'js/lib/contentfulGql';

import { imageFragment } from 'bundles/campaigns/contentful-queries/SharedFraments';

export const campaignHeroModuleFragment = contentfulGql`#graphql
  ${imageFragment}
  fragment CampaignHeroModule on PromoTemplateHero {
    __typename
    sys {
      id
    }
    eyebrow
    eyebrowBackground
    eyebrowImage {
      ...Image
    }
    headline
    description
    image {
      ...Image
    }
    ctaText
    ctaTarget
    secondaryCtaText
    secondaryCtaTarget
    secondaryCtaVariant
    layout
    backgroundColor
    backgroundImage {
      ...Image
    }
    height
    showBottomBorder
  }
`;
