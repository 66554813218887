import React from 'react';

import clsx from 'clsx';

import type { BaseComponentProps } from '@coursera/cds-common';
import { useId, useLocalizedStringFormatter } from '@coursera/cds-common';

import { CardClickArea } from '@core/cards/common';
import getCommonCardCss from '@core/cards/common/getCommonCardCss';
import getProductCardCss, {
  classes,
} from '@core/cards/ProductCard/getProductCardCss';
import i18nMessages from '@core/cards/ProductCard/i18n';

import CardAdminContent from './card/CardAdminContent';
import CardGridSkeleton from './card/CardGridSkeleton';
import CardListSkeleton from './card/CardListSkeleton';
import { isStringArray } from './card/CardStatusTags';
import ProductGridCard from './ProductGridCard';
import ProductListCard from './ProductListCard';
import type { ProductCardProps } from './types';

export type Props = ProductCardProps & Omit<BaseComponentProps<'div'>, 'title'>;

const commonStyles = getCommonCardCss();

const getAriaLabel = ({
  name,
  partnerNames,
  tagNames,
  productType,
}: {
  name?: string;
  partnerNames?: string;
  tagNames?: string;
  productType?: string;
}) => {
  // the card aria-label is a comma-separated strings in the specified order of items
  return [name, partnerNames, tagNames, productType]
    .filter((item) => item && item.trim() !== '')
    .join(', ');
};

/**
 * ProductCard presents a high-level product summary, and is an entry point for more detailed information.
 * This component contains a preview image, partner label, title, and product metadata.
 *
 * See [Props](__storybookUrl__/components-data-display-product-card--default#props) for usage info.
 */
const ProductCard = React.forwardRef(function ProductCard(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    id,
    className,
    variant,
    statusTags,
    partners,
    title,
    adminContent,
    alwaysSmallImage,
    productType,
    loading = false,
    'aria-label': ariaLabel,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    previewImageSrc,
    renderPreviewImage,
    body,
    footer,
    /* eslint-enable @typescript-eslint/no-unused-vars */
    ...rest
  } = props;

  const stringFormatter = useLocalizedStringFormatter(i18nMessages);
  const baseId = useId(id);
  const titleId = `${baseId}-product-card-title`;
  const partnerInfoId = `${baseId}-product-card-partner-info`;

  const partnerNames = partners?.map((p) => p.name).join(', ');

  // Convert tags to a comma-separated string
  const tagNames = isStringArray(statusTags)
    ? statusTags.join(', ')
    : undefined;

  const cardAriaLabel =
    ariaLabel ??
    getAriaLabel({
      partnerNames: partnerNames
        ? stringFormatter.format('product_card_offered_by_partners', {
            partnerNames,
          })
        : undefined,
      tagNames,
      name: title?.name,
      productType,
    });

  const cardProps = {
    ...props,
    'aria-label': cardAriaLabel,
    titleId,
    partnerInfoId,
  };

  return (
    <div
      ref={ref}
      className={clsx(classes.base, className, {
        [classes.grid]: variant === 'grid',
        [classes.list]: variant === 'list',
      })}
      css={[commonStyles, getProductCardCss(props)]}
      {...rest}
    >
      {loading && variant === 'grid' && <CardGridSkeleton />}
      {loading && variant === 'list' && (
        <CardListSkeleton alwaysSmallImage={alwaysSmallImage} />
      )}

      {!loading && (
        <>
          <CardClickArea>
            {variant === 'grid' && <ProductGridCard {...cardProps} />}
            {variant === 'list' && <ProductListCard {...cardProps} />}
          </CardClickArea>
          {adminContent && <CardAdminContent>{adminContent}</CardAdminContent>}
        </>
      )}
    </div>
  );
});

export default ProductCard;
