import * as React from 'react';

import MenuGoalTab from 'bundles/megamenu/components/tabs/MenuGoalTab';
import type { SubMenuData } from 'bundles/megamenu/types/MenuData';

type Props = {
  data?: SubMenuData;
  closeMenu: () => void;
  isMobile?: boolean;
};

const CLPTab = ({ data, closeMenu, isMobile }: Props) => {
  if (!data) return null;
  return (
    <MenuGoalTab subMenuData={data} menuType="CLP" tabId="learning-paths" closeMenu={closeMenu} isMobile={isMobile} />
  );
};

export default CLPTab;
