import React from 'react';

import clsx from 'clsx';

import type {
  IconProps,
  OverrideProps,
  OverridableComponent,
} from '@coursera/cds-common';
import { IconContext } from '@coursera/cds-common';

import ButtonBase from '@core/ButtonBase/ButtonBase';
import getButtonIconSize from '@core/utils/getButtonIconSize';
import useButtonPress from '@core/utils/useButtonPress';

import ButtonLoader from './ButtonLoader';
import type { variants } from './getButtonCss';
import getButtonCss, { classes } from './getButtonCss';

type BaseProps = {
  /**
   * Render an icon next to the button text.
   */
  icon?: React.ReactElement<IconProps>;

  /**
   * Specify positioning of the icon relative to the button text.
   * @default after
   */
  iconPosition?: 'before' | 'after';

  /**
   * If true, the button will be disabled.
   * If you need button to be still focusable please use `aria-disabled`.
   * @default false
   */
  disabled?: boolean;

  /**
   * Defines whether button should be full-width.
   * @default false
   */
  fullWidth?: boolean;

  /**
   * The size of the button.
   * @default medium
   */
  size?: 'small' | 'medium';

  /**
   * The variant to use.
   * @default primary
   */
  variant?: keyof typeof variants;

  /**
   * Define text to display in the button.
   */
  children: React.ReactNode;

  /**
   * Used only for `ghost` variants, defines which edge to align the button content.
   * @default none
   */
  edgeAlign?: 'start' | 'end';
  /**
   * If true, button will be disabled and a loading indicator will appear.
   */
  loading?: boolean;

  /**
   * Defines how the content of the button will be justified.
   *
   * @default center
   *
   * @example
   *
   *  <Button icon={<ArrowNextIcon />} fullWidth justifyContent="space-between">
   *    Next Item
   *  </Button>
   */
  justifyContent?: 'center' | 'space-between';

  /**
   * Defines how the content of the button will be aligned.
   *
   * @default center
   *
   * @example
   *
   * <Button icon={<ArrowNextIcon />} textAlign="start">
   *    Next Item
   *  </Button>
   */
  textAlign?: 'center' | 'start';
};

export interface ButtonTypeMap<D extends React.ElementType = 'button'> {
  props: BaseProps;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = ButtonTypeMap['defaultComponent']
> = OverrideProps<ButtonTypeMap<D>, D> & { component?: React.ElementType };

/**
 * Buttons are used to help users initiate an action or navigate to another page
 *
 * See [Props](__storybookUrl__/components-actions-button--default#props)
 */
const Button: OverridableComponent<ButtonTypeMap> = React.forwardRef(
  function Button(props: Props, ref: React.Ref<HTMLButtonElement>) {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      icon,
      iconPosition,
      size,
      variant,
      edgeAlign,
      onClick,
      children,
      loading,
      className,
      fullWidth,
      justifyContent = 'center',
      textAlign = 'center',
      onBlur,
      ...rest
    } = props;
    /* eslint-enable @typescript-eslint/no-unused-vars */

    const css = getButtonCss(props);

    const [isFocusVisible, setFocusVisible] = React.useState(false);

    const handleClick = useButtonPress({
      onClick,
      isDisabled:
        rest['aria-disabled'] === 'true' ||
        rest['aria-disabled'] === true ||
        rest.disabled ||
        loading,
    });

    const handleFocusVisible = () => {
      setFocusVisible(true);
    };

    const handleBlur = (event: React.FocusEvent<HTMLButtonElement>) => {
      setFocusVisible(false);
      onBlur?.(event);
    };

    const buttonSizedIcon = (
      <IconContext.Provider
        value={{ size: icon?.props?.size || getButtonIconSize(size) }}
      >
        {icon}
      </IconContext.Provider>
    );

    const startIcon =
      icon && iconPosition === 'before' ? (
        <span className={classes.startIcon}>{buttonSizedIcon}</span>
      ) : undefined;

    const endIcon =
      icon && iconPosition === 'after' ? (
        <span className={classes.endIcon}>{buttonSizedIcon}</span>
      ) : undefined;

    return (
      <ButtonBase
        ref={ref}
        className={clsx(className, classes.disableElevation, {
          [classes.loadingOpacity]: loading,
          [classes.disabled]: rest.disabled || rest['aria-disabled'],
          [classes[variant || 'primary']]: true,
        })}
        classes={{
          focusVisible: classes.focusVisible,
        }}
        css={css}
        onClick={handleClick}
        {...rest}
        aria-disabled={
          loading ? 'true' : rest['aria-disabled'] || rest.disabled
        }
        onBlur={handleBlur}
        onFocusVisible={handleFocusVisible}
      >
        <span className={classes.label}>
          {startIcon}
          {children}
          {endIcon}
        </span>
        {loading && <ButtonLoader isFocused={isFocusVisible} />}
      </ButtonBase>
    );
  }
);

Button.defaultProps = {
  size: 'medium',
  variant: 'primary',
  iconPosition: 'after',
};

export default Button;
