/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useRef } from 'react';

import MegaMenuSection from 'bundles/megamenu/components/sections/MegaMenuSection';
import useArrowKeys from 'bundles/megamenu/components/sections/useArrowKeys';
import {
  CERTIFICATE_SECTION_TYPE,
  DEGREES_SECTION_TYPE,
  GET_STARTED_SECTION_TYPE,
  POPULAR_SKILLS_SECTION_TYPE,
} from 'bundles/megamenu/constants/constants';
import type { MegaMenuDomainSections as MegaMenuDomainSectionsType } from 'bundles/megamenu/types/MegaMenuDomainSections';

const styles = {
  sectionsContainer: () => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 28px);
    margin: 0 -24px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  `,
  halfWidthSection: () => css`
    flex: 1 0 auto;
  `,
  linkSections: () => css`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    flex-wrap: wrap;
    width: 1px;

    @media screen and (max-width: 768px) {
      width: unset;
    }
  `,
  certificateAndDegreeSections: () => css`
    display: flex;
    flex: 3 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1px;

    @media screen and (max-width: 1300px) {
      flex: 1 0 auto;
    }

    @media screen and (max-width: 768px) {
      width: unset;
    }
  `,
  certificateAndDegreeItemWrapper: () => css`
    display: flex;
    flex-direction: row;
    width: 50%;

    @media screen and (max-width: 1300px) {
      width: 100%;
      flex-flow: column;
    }

    @media (max-width: 768px) {
      width: 100%;
      flex-flow: column;
    }
  `,
  fullWidthItemWrapper: () => css`
    width: 100%;
  `,
};

// dedicated megamenu domain sections component due to the complexity and responsive ability of the domain sections
const MegaMenuDomainSections = ({ sectionItems, isMobile, tabName, tabId }: MegaMenuDomainSectionsType) => {
  const divRef = useRef<HTMLDivElement>(null);

  useArrowKeys(divRef, sectionItems, isMobile);

  const leftSectionItems = sectionItems.filter(
    (section) => section.sectionType === GET_STARTED_SECTION_TYPE || section.sectionType === POPULAR_SKILLS_SECTION_TYPE
  );
  const certificateAndDegreeSectionItems = sectionItems.filter(
    (section) => section.sectionType === CERTIFICATE_SECTION_TYPE || section.sectionType === DEGREES_SECTION_TYPE
  );

  const certificateSectionItems = sectionItems.filter((section) => section.sectionType === CERTIFICATE_SECTION_TYPE);

  const degreesSectionItems = sectionItems.filter((section) => section.sectionType === DEGREES_SECTION_TYPE);

  const shouldShowCertificateAndDegreeSections = certificateAndDegreeSectionItems.length > 0;
  const shouldDivideIntoTwoSection = certificateAndDegreeSectionItems.length < 2;

  return (
    <div className="rc-MegaMenuDomainSections" css={styles.sectionsContainer} ref={divRef}>
      <div css={[styles.certificateAndDegreeSections, shouldDivideIntoTwoSection && styles.halfWidthSection]}>
        {shouldShowCertificateAndDegreeSections && (
          <React.Fragment>
            {degreesSectionItems.map((section) => (
              <div
                css={[
                  styles.certificateAndDegreeItemWrapper,
                  shouldDivideIntoTwoSection && styles.fullWidthItemWrapper,
                ]}
                key={`${section.sectionType}-div`}
              >
                <MegaMenuSection
                  key={section.sectionType}
                  fullWidth={true}
                  section={section}
                  isMobile={isMobile}
                  tabName={tabName}
                  tabId={tabId}
                />
              </div>
            ))}

            {certificateSectionItems.map((section) => (
              <div
                css={[
                  styles.certificateAndDegreeItemWrapper,
                  shouldDivideIntoTwoSection && styles.fullWidthItemWrapper,
                ]}
                key={`${section.sectionType}-div`}
              >
                <MegaMenuSection
                  key={section.sectionType}
                  fullWidth={true}
                  section={section}
                  isMobile={isMobile}
                  tabName={tabName}
                  tabId={tabId}
                />
              </div>
            ))}
          </React.Fragment>
        )}
      </div>
      <div css={styles.linkSections}>
        {leftSectionItems.map((section) => (
          <MegaMenuSection
            key={section.sectionType}
            fullWidth={true}
            section={section}
            hideAnchorColor={true}
            isMobile={isMobile}
            tabName={tabName}
            tabId={tabId}
          />
        ))}
      </div>
    </div>
  );
};

export default MegaMenuDomainSections;
