import type React from 'react';
import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import { compose } from 'recompose';

import { apolloFetchPolicy } from 'js/utils/apolloFetchPolicy';

import type { MegaMenuDataQuery as MegaMenuDataQueryType } from 'bundles/megamenu/components/hoc/__generated__/MegaMenuDataQuery';
import type { mappedMegaMenuAPIData, megaMenuAPIData } from 'bundles/megamenu/types/MenuData';

export type WithMegaMenuDataProps = { megaMenuAPIData?: mappedMegaMenuAPIData };

export const MegaMenuDataQuery = gql`
  query MegaMenuDataQuery {
    ExternallyAccessibleNostosV1Resource {
      getAllProperties(job_name: "megamenu_nostos_job", keys: "megamenu_nostos_key") {
        elements {
          id
          content
        }
      }
    }
  }
`;

/**
 * Transforms a flat array of menu items into a nested structure organized by section and domain.
 *
 * This utility function takes raw megamenu data and structures it into a hierarchical format
 * where items are grouped first by their section ID and then by their domain ID.
 */
export const groupBySectionAndDomain = (rows?: megaMenuAPIData[]) => {
  const acc: mappedMegaMenuAPIData = {};
  rows?.forEach((row) => {
    const { sectionid, domainid } = row;
    if (acc[sectionid]) {
      if (acc[sectionid][domainid]?.length > 0) {
        acc[sectionid][domainid].push(row);
      } else {
        acc[sectionid][domainid] = [row];
      }
    } else {
      acc[sectionid] = { [domainid]: [row] };
    }
  });
  return acc;
};

/**
 * Higher-order component that injects megamenu data into the wrapped component.
 *
 * This HOC fetches megamenu data from the Nostos API using GraphQL, processes it by grouping
 * items by section and domain, and provides the structured data to the wrapped component
 * via the `megaMenuAPIData` prop.
 */
const withMegaMenuData =
  () =>
  <T extends {}>(BaseComponent: React.ComponentType<T & WithMegaMenuDataProps>): React.ComponentType<T> =>
    compose<T & WithMegaMenuDataProps, T>(
      graphql<{}, MegaMenuDataQueryType, {}, WithMegaMenuDataProps>(MegaMenuDataQuery, {
        options: () => ({
          ...apolloFetchPolicy({ cacheForLoggedOutOnly: true }),
          ssr: false,
        }),
        props: ({ data }) => {
          const rows: megaMenuAPIData[] | undefined =
            data?.ExternallyAccessibleNostosV1Resource?.getAllProperties?.elements[0]?.content?.load_menu;
          // current data does not have grouping by section and domain
          return {
            megaMenuAPIData: rows && groupBySectionAndDomain(rows),
          };
        },
      })
    )(BaseComponent);

export default withMegaMenuData;
