import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { Props as ButtonProps } from './Button';

export const classes = generateEmotionClasses('button', [
  'label',
  'endIcon',
  'startIcon',
  'disabled',
  'focusVisible',
  'disableElevation',
  'loadingOpacity',
  'primary',
  'primaryInvert',
  'secondary',
  'ghost',
  'ghostInvert',
]);

const disableElevation = css`
  &.${classes.disableElevation} {
    &::after {
      content: unset;
    }
  }
`;

export const getEdgeAlignMargin = (
  size: string,
  props: Partial<ButtonProps>
) => {
  const { variant, edgeAlign } = props;

  const leftOrRight = edgeAlign === 'start' ? 'left' : 'right';
  const marginSize =
    size === 'medium' ? 'var(--cds-spacing-200)' : 'var(--cds-spacing-100)';

  if (edgeAlign && (variant === 'ghost' || variant === 'ghostInvert')) {
    return `margin-${leftOrRight}: calc(${marginSize} * -1);`;
  } else {
    return '';
  }
};

export const variants = {
  primary: css`
    color: var(--cds-color-neutral-primary-invert);
    background-color: var(--cds-color-interactive-primary);
    box-shadow: inset 0 0 0 1px var(--cds-color-interactive-primary);

    &:hover {
      border-color: var(--cds-color-interactive-primary-hover);
      background-color: var(--cds-color-interactive-primary-hover);
    }

    &:active {
      border-color: var(--cds-color-interactive-primary-pressed);
      background-color: var(--cds-color-interactive-primary-pressed);

      ${disableElevation}
    }

    &.${classes.disabled} {
      color: var(--cds-color-neutral-disabled);
      background: var(--cds-color-neutral-disabled-strong);
      box-shadow: inset 0 0 0 1px var(--cds-color-neutral-disabled-strong);
    }
  `,

  primaryInvert: css`
    color: var(--cds-color-interactive-primary);
    background-color: var(--cds-color-neutral-background-primary);

    &:hover {
      color: var(--cds-color-interactive-primary-hover);
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
    }

    &.${classes.focusVisible} {
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
      color: var(--cds-color-interactive-primary-hover);
    }

    &:active {
      color: var(--cds-color-interactive-primary-pressed);
      background-color: var(
        --cds-color-interactive-background-primary-pressed-weak
      );

      ${disableElevation}
    }

    &.${classes.disabled} {
      color: var(--cds-color-neutral-disabled-strong);
      background: var(--cds-color-neutral-background-primary-weak);
    }
  `,

  secondary: css`
    color: var(--cds-color-interactive-primary);
    background-color: var(--cds-color-neutral-background-primary);
    box-shadow: inset 0 0 0 1px var(--cds-color-interactive-primary);

    &:hover {
      color: var(--cds-color-interactive-primary-hover);
      box-shadow: inset 0 0 0 1px var(--cds-color-interactive-primary-hover);
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
    }

    &.${classes.focusVisible} {
      box-shadow: inset 0 0 0 1px var(--cds-color-interactive-primary-hover);
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
      color: var(--cds-color-interactive-primary-hover);
    }

    &:active {
      color: var(--cds-color-interactive-primary-pressed);
      box-shadow: inset 0 0 0 1px var(--cds-color-interactive-primary-pressed);
      background-color: var(
        --cds-color-interactive-background-primary-pressed-weak
      );

      ${disableElevation}
    }

    &.${classes.disabled} {
      color: var(--cds-color-neutral-disabled-strong);
      background: var(--cds-color-neutral-background-primary-weak);
      box-shadow: inset 0 0 0 1px var(--cds-color-neutral-disabled-strong);
    }
  `,

  ghost: css`
    text-decoration: none;
    color: var(--cds-color-interactive-primary);

    &:hover {
      text-decoration: underline;
      color: var(--cds-color-interactive-primary-hover);
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
    }

    &.${classes.focusVisible} {
      text-decoration: underline;
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
      color: var(--cds-color-interactive-primary-hover);
    }

    &:active {
      text-decoration: underline;
      color: var(--cds-color-interactive-primary-pressed);
      background-color: var(
        --cds-color-interactive-background-primary-pressed-weak
      );

      ${disableElevation}
    }

    &.${classes.disabled} {
      color: var(--cds-color-neutral-disabled-strong);
    }
  `,

  ghostInvert: css`
    background: transparent;
    color: var(--cds-color-neutral-primary-invert);

    &:hover {
      background: transparent;
      text-decoration: underline;
    }

    &.${classes.focusVisible} {
      text-decoration: underline;
    }

    &:active {
      text-decoration: underline;
      ${disableElevation}
    }

    &.${classes.disabled} {
      background: transparent;
      text-decoration: underline;
      color: var(--cds-color-neutral-disabled-strong);
    }
  `,
};

export const sizes = {
  small: (props: ButtonProps) => css`
    ${typography.actionSecondary};

    padding: ${props.variant === 'ghost' || props.variant === 'ghostInvert'
      ? 'var(--cds-spacing-100)'
      : 'var(--cds-spacing-100) var(--cds-spacing-200)'};

    ${getEdgeAlignMargin('small', props)}
  `,

  medium: (props: ButtonProps) => css`
    ${typography.actionPrimary};

    padding: ${props.variant === 'ghost' || props.variant === 'ghostInvert'
      ? 'var(--cds-spacing-150) var(--cds-spacing-200)'
      : 'var(--cds-spacing-150) var(--cds-spacing-300)'};

    ${getEdgeAlignMargin('medium', props)}
  `,
};

export default (props: ButtonProps) => css`
  border-radius: var(--cds-border-radius-50);
  text-transform: none;
  text-align: ${props.textAlign};
  max-width: ${props.fullWidth ? 'none' : '320px'};
  ${props.fullWidth && 'width: 100%;'}
  min-width: unset;
  transition: none;
  box-sizing: border-box;
  justify-content: ${props.justifyContent};

  ${variants[props.variant as NonNullable<ButtonProps['variant']>]};
  ${sizes[props.size as NonNullable<ButtonProps['size']>](props)};

  .${classes.label} {
    text-transform: none;
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
  }

  &.${classes.loadingOpacity} {
    .${classes.label} {
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }

  &.${classes.focusVisible} {
    outline: 1px solid transparent;
    outline-offset: 2px;

    &.${classes.disableElevation} {
      &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        border-radius: var(--cds-border-radius-50);
        box-shadow: 0 0 0 1px var(--cds-color-interactive-stroke-primary-focus),
          0 0 0 2px var(--cds-color-interactive-stroke-primary-focus-invert);
      }
    }
  }

  .${classes.endIcon} {
    margin-right: 0;
    display: inherit;
    margin-left: var(--cds-spacing-50);
  }

  .${classes.startIcon} {
    margin-left: 0;
    display: inherit;
    margin-right: var(--cds-spacing-50);
  }
`;
