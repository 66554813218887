import * as Sentry from '@sentry/react';
import gql from 'graphql-tag';

import sanitizeString from 'bundles/internationalization-lib/utils/sanitizeString';

/**
 * Creates the Contenful query AST document with the correct parameters to works with the i18n system
 * @param contentType - the contentType id as found in contentful, for example "loggedOutHomePageContent"
 * @param fragmentDoc - the generated fragment document for example this -> https://github.com/webedx-spark/web/blob/7fb53c02f0866687f1f01ec5c11b197cef6d4638/static/bundles/front-page/__generated__/contentfulOperations.tsx#L93
 * @param fragmentFieldsName - the most top level fragments fields name, in the same example as above, that would be "LoggedOutHomePageContentFields"
 * @param filters - used to make the query more specific.
 * @returns the full contentful query AST document
 */
export const getContentfulQuery = (
  contentType: string,
  fragmentDoc: $TSFixMe, // this type should be a AST document from graphql, TS is supported in V3 of apollo but does not seem to be in v2
  fragmentFieldsName: string,
  filters?: Record<string, string>
) => {
  try {
    const queryName = `get${contentType[0].toUpperCase()}${contentType.slice(1)}`;
    const optionalCondition =
      filters && Object.keys(filters).length
        ? `, {${Object.keys(filters)
            .map((key) => `${key}: "${sanitizeString(filters[key])}"`)
            .join(',')}}`
        : '';

    // defining the query as a template literal to replace the fragmentFieldsName first and then passing it to gql``
    // is a workaround to avoid GraphQLError: Syntax Error: Expected {, found }
    // this is a known issue when using template literal in gql  https://github.com/graphql/graphiql/issues/1842
    const query = `#graphql
    query ${queryName}($preview: Boolean!, $countryCode: String!, $languageCode: String!, $variant: String!, $locale: String!) {
      ${contentType}Collection(preview:$preview,where:{AND: [ { countryCode: $countryCode},{ variant: $variant},{ languageCode: $languageCode}${optionalCondition}] }, limit:1, locale: $locale) {
        items {
          ...${fragmentFieldsName}
        }
      }
    }
  `;

    return gql`
      ${query}
      ${fragmentDoc}
    `;
  } catch (error) {
    Sentry.captureException('An Error occurred while generating contentful query using the params provided', {
      extra: { error, contentType, filters },
    });
    return undefined;
  }
};
