import contentfulGql from 'js/lib/contentfulGql';

/**
 * Common content model fragments
 */

export const imageFragment = contentfulGql`#graphql
  fragment Image on Asset {
    __typename
    sys {
      id
    }
    width
    height
    url
    title
    description
  }
`;
