import { useLocalizedStringFormatter } from '@coursera/cds-common';
import { CloseIcon } from '@coursera/cds-icons';

import type { ButtonProps } from '@core/Button';
import IconButtonBase from '@core/IconButton/IconButtonBase';

import messages from './i18n';

export type Props = {
  invert?: boolean;
  onClick: ButtonProps['onClick'];
  className?: ButtonProps['className'];
};

const CloseButton = (props: Props) => {
  const { invert = false, ...rest } = props;
  const stringFormatter = useLocalizedStringFormatter(messages);

  return (
    <IconButtonBase
      aria-label={stringFormatter.format('close')}
      size="small"
      variant={invert ? 'ghostInvert' : 'ghost'}
      {...rest}
    >
      <CloseIcon />
    </IconButtonBase>
  );
};

export default CloseButton;
