/**
 *
 * Maps the size of the button to new icon size.
 * After releasing the new icons with 3.0 are large than the old ones in all our buttons.
 *
 */
const sizeMap = {
  small: 'medium',
  medium: 'large',
} as const;

const getButtonIconSize = (size?: keyof typeof sizeMap) => {
  if (size && size in sizeMap) {
    return sizeMap[size];
  }
  return size;
};

export default getButtonIconSize;
