import React from 'react';

import { css } from '@emotion/react';

import type { TagProps } from '@core/Tag';
import Tag from '@core/Tag';

// override default Tag styles confirmed by Search team experiments to perform better.
const styles = css`
  background-color: var(--cds-color-neutral-background-primary) !important;
  color: var(--cds-color-neutral-primary) !important;
  border-color: var(--cds-color-neutral-stroke-primary) !important;
`;

/**
 * Styled Tag to be specifically used in the context of ProductCard status tags.
 *
 */
const CardStatusTag = React.forwardRef<HTMLSpanElement, TagProps>(
  function _CardStatusTag(props, ref) {
    return (
      <Tag
        ref={ref}
        css={styles}
        priority="secondary"
        variant="status"
        {...props}
      />
    );
  }
);

export default CardStatusTag;
