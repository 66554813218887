// popular skills experiment doc: https://docs.google.com/document/d/13AF2JwpHMQEHcLitcHBzer6skC8oWk1-c0XEn5xVyRY
import type React from 'react';
import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import { startCase } from 'lodash';
import { compose } from 'recompose';

import { apolloFetchPolicy } from 'js/utils/apolloFetchPolicy';

import type { MegaMenuSkillDataQuery as MegaMenuSkillDataQueryType } from 'bundles/megamenu/components/hoc/__generated__/MegaMenuSkillDataQuery';
import { MEGAMENU_SECTION_ID_POPULAR_SKILLS } from 'bundles/megamenu/constants/constants';
import type { mappedMegaMenuAPIData, megaMenuAPIData as megaMenuAPIDataType } from 'bundles/megamenu/types/MenuData';
import { removeWWWFromUrl } from 'bundles/megamenu/utils/MegaMenuUtils';

type withMegaMenuDataProps = { megaMenuAPIData?: mappedMegaMenuAPIData };

export type MegaMenuSkillElement = {
  id: string;
  content: {
    skill_data: Array<{
      listIdentifier: string;
      rank: number;
      url: string;
    }>;
  };
};

export const MegaMenuSkillDataQuery = gql`
  query MegaMenuSkillDataQuery {
    ExternallyAccessibleNostosV1Resource {
      getAllProperties(
        job_name: "megamenu_popular_terms_by_domain"
        keys: "data-science,business,computer-science,information-technology,language-learning,life-sciences,personal-development,physical-science-and-engineering,social-sciences,arts-and-humanities,math-and-logic"
      ) {
        elements {
          id
          content
        }
      }
    }
  }
`;

// because _.startCase does not take care of these cases
const manualCapitalizationMapping: { [key: string]: string } = {
  'c++': 'C++',
  gis: 'GIS',
  aws: 'AWS',
  sap: 'SAP',
  iot: 'IoT',
  gcp: 'GCP',
  sql: 'SQL',
  devops: 'DevOps',
  'power bi': 'Power BI',
};

/**
 * Transforms skill data API elements into the standard megamenu data format.
 *
 * This utility function converts the raw API skill data into properly formatted
 * megamenu items, applying appropriate capitalization rules and organizing the data
 * by domain ID. Each item is assigned to the "popular skills" section and gets
 * proper formatting for titles and URLs.
 */
export const mapToMegaMenuData = (elements: (MegaMenuSkillElement | null)[]): Record<string, megaMenuAPIDataType[]> => {
  const acc: Record<string, megaMenuAPIDataType[]> = {};
  elements.forEach((element) => {
    const items = element?.content?.skill_data.map(
      (item) =>
        item && {
          domainid: element.id,
          sectionid: MEGAMENU_SECTION_ID_POPULAR_SKILLS,
          title: manualCapitalizationMapping[item.listIdentifier] || startCase(item.listIdentifier),
          url: removeWWWFromUrl(item.url),
        }
    );
    if (element && items) acc[element.id] = items;
  });
  return acc;
};

/**
 * Higher-order component that enhances megamenu data with popular skills information.
 *
 * This HOC fetches popular skills data from the Nostos API using GraphQL, and adds it
 * to the existing megamenu data structure. It expects to work with a component that already
 * has megaMenuAPIData (typically from withMegaMenuData HOC), and enhances that data with
 * an additional "popular skills" section.
 */
const withMegaMenuData =
  () =>
  <T extends withMegaMenuDataProps>(
    BaseComponent: React.ComponentType<T & withMegaMenuDataProps>
  ): React.ComponentType<T> =>
    compose<T & withMegaMenuDataProps, T>(
      graphql<T, MegaMenuSkillDataQueryType, {}, withMegaMenuDataProps>(MegaMenuSkillDataQuery, {
        options: () => ({
          ...apolloFetchPolicy({ cacheForLoggedOutOnly: true }),
          ssr: false,
        }),
        props: ({ data, ownProps }) => {
          const { megaMenuAPIData } = ownProps;
          const elements = data?.ExternallyAccessibleNostosV1Resource?.getAllProperties?.elements;
          const popularSkillsData = elements && mapToMegaMenuData(elements);

          // intentionally setting megaMenuAPIData to undefined until we load the popular skill data
          if (!megaMenuAPIData || !popularSkillsData) return { megaMenuAPIData: undefined };

          megaMenuAPIData[MEGAMENU_SECTION_ID_POPULAR_SKILLS] = popularSkillsData;

          return { megaMenuAPIData };
        },
      })
    )(BaseComponent);

export default withMegaMenuData;
