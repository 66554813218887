/* eslint-disable no-nested-ternary */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { isAuthenticatedUser } from 'js/lib/user';

import { Colors, Typography2, typography, typography2 } from '@coursera/cds-core';
import { CloseIcon } from '@coursera/cds-icons';

import { TrackedButton } from 'bundles/common/components/TrackedCui';
import MegaMenuDomainSections from 'bundles/megamenu/components/sections/MegaMenuDomainSections';
import MegaMenuSections from 'bundles/megamenu/components/sections/MegaMenuSections';
import MobileMegaMenuSections from 'bundles/megamenu/components/sections/MobileMegaMenuSections';
import { DOMAIN_TAB_TYPE, MAIN_MENU_WIDTH } from 'bundles/megamenu/constants/constants';
import type { MenuGoalTab as MenuGoalTabType } from 'bundles/megamenu/types/MenuGoalTab';
import { useIsSimplifiedPageHeader } from 'bundles/page-header/contexts/PageHeaderContext';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/megamenu';

const styles = {
  grid: () => css`
    width: 940px;
    height: 100%;

    @media screen and (max-width: 1300px) {
      width: calc(100vw - ${48 * 2 + MAIN_MENU_WIDTH}px) !important;

      /*  padding 48 x2 + main menu 300 = 396 */
      position: relative;
      z-index: 1000;
    }
  `,
  gridPreUnifiedMobile: css`
    position: static;
    width: auto !important;
    height: auto !important;

    @media screen and (max-width: 1300px) {
      /* override the !important style defined in .box class above */
      width: auto !important;
    }
  `,
  menuContainer: () => css`
    position: absolute;
    top: 0;
    height: 100%;
    width: 940px;
    padding: 36px 52px;
    overflow-y: scroll;

    @media screen and (max-width: 1300px) {
      width: 100%;
    }

    @media screen and (max-width: 991px) {
      height: 100vh;
      width: 100vw;
      padding: 20px 20px 190px;
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgb(0 0 0 / 50%);

      /* should match background, can't be transparent */
    }

    .close-button {
      top: 12px;
      right: 12px;
    }
  `,
  menuContainerPreUnifiedMobile: css`
    position: static !important;
    width: auto !important;
    overflow: visible !important;
    padding: 130px 0 !important;
  `,
  mobileLoggedOutMenuContainer: () => css`
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    padding: 140px 20px 190px;
  `,
  menuTitle: () => css`
    ${typography.h2semibold}
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  `,
  mobileMenuTitle: () => css`
    ${typography.h2semibold}
    font-size: 22px;
    line-height: 24px;
    font-weight: bold;
    padding: 120px 0 20px 0;
  `,
  menuSubTitle: () => css`
    ${typography2.subtitleMedium}
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    margin-left: 1px;
    color: ${Colors.gray[700]};
  `,
  textContainer: () => css`
    margin-bottom: 28px;
  `,
  mobileTextContainer: () => css`
    margin-bottom: -20px;
  `,
};

const MenuGoalTab = ({ menuType, subMenuData, domainSlug, tabId, closeMenu, isMobile }: MenuGoalTabType) => {
  const { title, subTitle, sections } = subMenuData;
  const isDomainTab = menuType === DOMAIN_TAB_TYPE;
  const isSimplifiedPageHeader = useIsSimplifiedPageHeader();
  const isPreUnifiedMobilePageHeader = isSimplifiedPageHeader && isMobile;

  return (
    <div className="rc-MenuGoalTab" css={[styles.grid, isPreUnifiedMobilePageHeader && styles.gridPreUnifiedMobile]}>
      <section
        className={`rc-${menuType}Menu`}
        css={[
          isMobile && isAuthenticatedUser() ? styles.mobileLoggedOutMenuContainer : styles.menuContainer,
          isPreUnifiedMobilePageHeader && styles.menuContainerPreUnifiedMobile,
        ]}
        data-e2e={`megamenu-${menuType}-menu`}
      >
        <TrackedDiv
          trackingName="mega_menu_tab"
          trackClicks={false}
          trackMouseEnters={true}
          data={{ title, domainSlug, menuType }}
        >
          <div className={`${menuType}Menu-textContent`} css={styles.textContainer}>
            <Typography2
              variant="subtitleMedium"
              component="p"
              className={`${menuType}Menu-title`}
              css={styles.menuTitle}
            >
              {title}
            </Typography2>
            {subTitle && (
              <p className={`${menuType}Menu-subTitle`} css={styles.menuSubTitle}>
                {subTitle}
              </p>
            )}
          </div>
          {isDomainTab ? (
            <MegaMenuDomainSections
              sectionItems={sections}
              domainName={title}
              tabName={title}
              tabId={tabId}
              isMobile={isMobile}
            />
          ) : isMobile ? (
            <MobileMegaMenuSections sectionItems={sections} tabName={title} tabId={tabId} isMobile={true} />
          ) : (
            <MegaMenuSections sectionItems={sections} tabName={title} tabId={tabId} isMobile={isMobile} />
          )}
          {!isMobile && (
            <TrackedButton
              rootClassName="close-button"
              type="noStyle"
              size="sm"
              onClick={closeMenu}
              label={<CloseIcon size="large" />}
              aria-label={_t('Close megamenu')}
              trackingName="megamenu_close_button"
              trackingData={{ title, domainSlug, menuType }}
              data-e2e="megamenu-close-button"
              style={{ position: 'absolute' }}
            />
          )}
        </TrackedDiv>
      </section>
    </div>
  );
};

export default MenuGoalTab;
