import React from 'react';

import clsx from 'clsx';

import { IconContext } from '@coursera/cds-common';

import type { ButtonBaseProps } from '@core/ButtonBase';
import ButtonBase from '@core/ButtonBase';
import getButtonIconSize from '@core/utils/getButtonIconSize';

import getIconButtonCss, { classes } from './iconButtonCss';

export type BaseProps = {
  /**
   * If set to true, button will be disabled.
   * Note: If set button will be removed from the a11y tree and user will not be able
   * to focus it with `Tab` key.
   */
  disabled?: boolean;

  /**
   * Sets the size of the button.
   * @default medium
   */
  size?: 'small' | 'medium';

  /**
   * Sets the variant of the button.
   * @default primary
   */
  variant?: 'ghost' | 'ghostInvert' | 'primary' | 'primaryInvert' | 'secondary';
};

/**
 * Styled button proxy component, used as a base for IconButton. Emotion wraps the component with EmotionCssPropInternal that Tooltip doesn't like.
 * Tooltip throws a warning Failed prop type: Invalid prop `children` supplied to `ForwardRef(Tooltip)`. Expected an element that can hold a ref.
 *
 * @internal Component also is used for IconButtons that don't have Tooltip.
 */

const IconButtonBase = React.forwardRef<
  HTMLButtonElement,
  BaseProps & ButtonBaseProps
>(function _IconButtonBase(props, ref) {
  const {
    disabled,
    size = 'medium',
    variant = 'primary',
    className,
    'aria-disabled': ariaDisabled,
    children,
    ...rest
  } = props;

  return (
    <ButtonBase
      ref={ref}
      aria-disabled={disabled || ariaDisabled}
      className={clsx(className, {
        [classes[size]]: true,
        [classes[variant]]: true,
        [classes.disabled]: disabled || ariaDisabled,
      })}
      css={getIconButtonCss}
      disabled={disabled}
      {...rest}
    >
      <IconContext.Provider value={{ size: getButtonIconSize(size) }}>
        {children}
      </IconContext.Provider>
    </ButtonBase>
  );
});

export default IconButtonBase;
