import * as React from 'react';
import { withApollo } from 'react-apollo';

import type ApolloClient from 'apollo-client';
import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import connectToRouter from 'js/lib/connectToRouter';
import connectToStores from 'js/lib/connectToStores';
import user from 'js/lib/user';

import { defaultLOHPDegreeListsV3Options } from 'bundles/browse/components/Domain/withDegreeListsV3';
import { getPremiumProductCollectionsQueryOption } from 'bundles/browse/components/Domain/withPremiumProductCollections';
import { DomainGetAllQuery } from 'bundles/browse/components/Queries';
import { lohpDegreeListsV3Query } from 'bundles/front-page/common/Queries';
import { getLOHPLocalizedQueryOption } from 'bundles/front-page/components/FrontPageDataProvider';
import { LOHP_PREMIUM_PRODUCT_COLLECTIONS_VARIABLES } from 'bundles/front-page/hoc/WithCollectionsData';
import { getInternationalizationVariables } from 'bundles/front-page/utils';
import { getMegamenuPremiumProductCollectionsVariables } from 'bundles/page-header/components/optimized/MegaMenuContent';
import MegaMenuQuery from 'bundles/page-header/components/queries/MegaMenu';
import CookiesContext from 'bundles/page/contexts/CookiesContext';
import CsrfTokenContext from 'bundles/page/contexts/CsrfTokenContext';
import PageLoadEventContext from 'bundles/page/contexts/PageLoadEventContext';
import RequestCountryCodeContext from 'bundles/page/contexts/RequestCountryCodeContext';
import RouterContext from 'bundles/page/contexts/RouterContext';
import UserAgentApiContext from 'bundles/page/contexts/UserAgentApiContext';
import type { Cookies, UserAgentApi } from 'bundles/page/types';
import type { ServerRouterData } from 'bundles/page/types/router';
import withHasPageLoaded from 'bundles/page/utils/withHasPageLoaded';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';

import 'css!bundles/front-page/styl/index';

type Props = {
  userAgentApi: UserAgentApi;
  cookies: Cookies;
  router: ServerRouterData;
  children: React.ReactNode;
  requestCountryCode: string;
  csrfToken?: string;
  hasPageLoaded?: boolean;
  client: ApolloClient<$TSFixMe>;
  isPreview: boolean;
};

export class DataFetchBoundary extends React.LegacyComponentWithChildren {
  // short circuit tree walking to make ssr faster/more efficient
  dataFetchingBoundary = true;

  render() {
    const { children } = this.props;
    return children;
  }
}

// To be used at page level, injects common contexts.
export class FrontPageApplicationWithPrefetch extends React.LegacyComponentWithChildren<Props> {
  componentDidMount() {
    this.fetchData(); // still pre-fetch on CSR if SSR fails
  }

  getCollectionSectionQueryOptions = () => {
    return [
      getPremiumProductCollectionsQueryOption({
        variables: LOHP_PREMIUM_PRODUCT_COLLECTIONS_VARIABLES,
        ssr: false,
      }),
    ];
  };

  getLOHPGraphqlPromises = () => {
    const { countryCode, languageCode } = getInternationalizationVariables(this.props.router.location.pathname);

    const queryOptions = [
      getLOHPLocalizedQueryOption({ isPreview: this.props.isPreview, countryCode, languageCode }),
      { ...defaultLOHPDegreeListsV3Options, query: lohpDegreeListsV3Query },
      { query: MegaMenuQuery },
      { query: DomainGetAllQuery },
      getPremiumProductCollectionsQueryOption({ variables: getMegamenuPremiumProductCollectionsVariables() }),
      ...this.getCollectionSectionQueryOptions(),
    ];
    return queryOptions.map((options) => {
      return this.props.client.query<$TSFixMe>({ ...options, fetchPolicy: 'cache-first' as const });
    });
  };

  // prefetch data and stuff in cache for faster loading
  fetchData = async () => {
    // First series of queries
    await Promise.all(this.getLOHPGraphqlPromises());
  };

  render() {
    return (
      <DataFetchBoundary>
        <RouterContext.Provider value={this.props.router}>
          <UserAgentApiContext.Provider value={this.props.userAgentApi}>
            <CookiesContext.Provider value={this.props.cookies}>
              <CsrfTokenContext.Provider value={this.props.csrfToken || '<MISSING>'}>
                <RequestCountryCodeContext.Provider value={this.props.requestCountryCode || 'US'}>
                  <PageLoadEventContext.Provider value={{ hasPageLoaded: this.props.hasPageLoaded || false }}>
                    {this.props.children}
                  </PageLoadEventContext.Provider>
                </RequestCountryCodeContext.Provider>
              </CsrfTokenContext.Provider>
            </CookiesContext.Provider>
          </UserAgentApiContext.Provider>
        </RouterContext.Provider>
      </DataFetchBoundary>
    );
  }
}

export default compose<Props, {}>(
  connectToRouter((router) => {
    const location = router.location;
    const previewParam = location?.query?.preview;
    const isPreview = !!user.isSuperuser() && !!previewParam && Number(previewParam) === 1;
    return {
      router,
      isPreview,
    };
  }),
  connectToStores<Props, {}>([ApplicationStoreClass], (ApplicationStore, props) => {
    const appState = ApplicationStore.getState();
    return {
      ...props,
      csrfToken: appState.csrfToken,
      requestCountryCode: appState.requestCountryCode,
      cookies: appState.cookies,
      userAgentApi: appState.userAgent,
    };
  }),
  Retracked.createTrackedContainer(() => {
    return {
      namespace: {
        app: 'front_page',
        page: 'front_page_story',
      },
    };
  }),
  withHasPageLoaded,
  withApollo
)(FrontPageApplicationWithPrefetch);
