import * as React from 'react';

import { useQuery } from '@apollo/client';

import { useLocation } from 'js/lib/useRouter';

import type { CampaignQueryQuery, CampaignQueryQueryVariables } from 'bundles/campaigns/__generated__/contentfulTypes';
import { campaignQuery } from 'bundles/campaigns/contentful-queries/CampaignQuery';
import CampaignModulesDataContext from 'bundles/campaigns/context/CampaignModulesDataContext';
import shouldShowCampaigns from 'bundles/campaigns/utils/shouldShowCampaigns';
import { getClientName } from 'bundles/cms/utils/SpaceUtils';

const CampaignModulesDataProviderForLohp = ({ children }: React.PropsWithChildren) => {
  const { query } = useLocation();
  const isPreview = query.preview === 'true' || query.preview === '1';

  // TODO: replace with getCampaignModulesFor{Placement} query when available
  // https://coursera.atlassian.net/browse/MERCH-1197
  const { data, loading } = useQuery<CampaignQueryQuery, CampaignQueryQueryVariables>(campaignQuery, {
    context: { clientName: getClientName('MARKETING', isPreview) },
    variables: {
      id: '12IiDUqjgqH0p653ZzmRyh',
      preview: isPreview,
    },
    errorPolicy: 'all',
    ssr: true,
    skip: !shouldShowCampaigns(),
  });

  let heroModuleData;
  let collectionsModuleData;
  let promoBarModuleData;

  data?.campaign?.regionsCollection?.items?.[0]?.surfaceAreasCollection?.items?.forEach((item) => {
    if (item?.__typename === 'CampaignHeroModule') {
      heroModuleData = item.creativesForVariantBCollection?.items?.[0];
    }
    if (item?.__typename === 'CampaignSpotlightModule') {
      collectionsModuleData = item.creativesForVariantBCollection?.items?.[0];
    }
    if (item?.__typename === 'CampaignPromoBar') {
      promoBarModuleData = item.creativesForVariantBCollection?.items?.[0];
    }
  });

  return (
    <CampaignModulesDataContext.Provider
      value={{
        loading,
        campaignData: {
          heroModule: {
            contentData: heroModuleData,
          },
          collectionsModule: {
            contentData: collectionsModuleData,
          },
          promoBarModule: {
            contentData: promoBarModuleData,
          },
        },
      }}
    >
      {children}
    </CampaignModulesDataContext.Provider>
  );
};

export default CampaignModulesDataProviderForLohp;
