import contentfulGql from 'js/lib/contentfulGql';

import { imageFragment } from 'bundles/campaigns/contentful-queries/SharedFraments';

export const campaignPromoBarModuleFragment = contentfulGql`#graphql
  ${imageFragment}

  fragment CampaignPromoBarModule on PromoTemplatePromoBar {
    __typename
    sys {
      id
    }
    headline
    description
    textLayout
    backgroundColor
    bannerColor
    centerContent
    primaryCtaText
    primaryCtaTarget
    hideBackgroundImages
  }
`;
