/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { MenuItem as ComplexMenuItemType } from 'bundles/megamenu/types/MenuItem';

const styles = {
  name: () => css`
    font-size: 14px;
    line-height: 20px;
  `,
  partnerName: () => css`
    font-size: 12px;
    line-height: 16px;
    color: #5e5e5e;
  `,
  text: () => css`
    color: #1f1f1f;
  `,
};

const DescriptionWithPartner = ({ item }: ComplexMenuItemType) => {
  const { name, partnerName } = item;

  return (
    <div className="rc-DescriptionWithPartner">
      {partnerName && (
        <div className="megaMenuGoalItem-partnerName" css={styles.partnerName}>
          {partnerName}
        </div>
      )}
      <div className="megaMenuGoalItem-name" css={[styles.name, styles.text]}>
        {name}
      </div>
    </div>
  );
};

export default DescriptionWithPartner;
