import clsx from 'clsx';

import CardStatusTag from '@core/cards/ProductCard/card/CardStatusTag';
import { classes } from '@core/cards/ProductCard/getProductCardCss';

export const isStringArray = (
  tags: string[] | React.ReactNode
): tags is string[] => {
  return Array.isArray(tags) && typeof tags[0] === 'string';
};

export type Props = {
  /**
   * Tags to display on the card.
   * Tags can be strings or objects with props to pass to the Tag component.
   */
  tags: string[] | React.ReactNode;

  /**
   * Whether tags should overlay on top of other content in the layout.
   * e.g. for <CardPreviewGrid/> the tags lay on top of the preview image.
   * @default false
   */
  shouldOverlay?: boolean;
};

const CardStatusTags = ({ tags, shouldOverlay = false }: Props) => {
  if (Array.isArray(tags) && tags.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx(classes.statusTags, {
        [classes.statusTagsSimple]: !shouldOverlay,
        [classes.statusTagsOverlay]: shouldOverlay,
      })}
    >
      {isStringArray(tags)
        ? tags.map((tag) => <CardStatusTag key={tag}>{tag}</CardStatusTag>)
        : tags}
    </div>
  );
};

export default CardStatusTags;
