import * as React from 'react';

import MenuGoalTab from 'bundles/megamenu/components/tabs/MenuGoalTab';
import { DOMAIN_TAB_TYPE } from 'bundles/megamenu/constants/constants';
import type { MenuItem } from 'bundles/megamenu/types/MenuData';

type Props = {
  menuItem?: MenuItem;
  closeMenu: () => void;
  isMobile?: boolean;
};

const DomainTab = ({ menuItem, closeMenu, isMobile }: Props) => {
  if (!menuItem || !menuItem.subMenuData) return null;
  return (
    <MenuGoalTab
      subMenuData={menuItem.subMenuData}
      menuType={DOMAIN_TAB_TYPE}
      domainSlug={menuItem?.slug}
      tabId={menuItem?.id}
      closeMenu={closeMenu}
      isMobile={isMobile}
    />
  );
};

export default DomainTab;
