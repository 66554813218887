import contentfulGql from 'js/lib/contentfulGql';

import { imageFragment } from 'bundles/campaigns/contentful-queries/SharedFraments';

export const campaignCollectionsModuleFragment = contentfulGql`#graphql
  ${imageFragment}
  fragment CampaignCollectionsModule on PromoTemplateCollections {
    __typename
    sys {
      id
    }
    backgroundColor
    backgroundImage {
      ...Image
    }
    layout
    showRoundedCorners

    headline
    description
    showAllEntries
    numOfEntriesPerCollection

    ctaText
    ctaTarget
    ctaVariant

    collectionSetsCollection {
      items {
        sys {
          id
        }
        filterName
        filterImage {
         ...Image
        }
        collectionModuleId
        presetCollectionId
      }
    }
  }
`;
